$button-size: 42px;

.container {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  overflow: hidden;
  padding: 0 $button-size/2;

  &.extendToEdge {
    padding-right: 0;
    .titlebar {
      padding-right: $button-size/2;
    }
  }
}

.titlebar {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;

  .arrows {
    display: flex;
    align-self: center;
    .arrow {
      padding-left: 10px;
    }
  }
}

.arrow {
  cursor: pointer;
  padding: 0;
  outline: none;
  transition: opacity .2s ease-in-out;
  z-index: 3;

  &.hide {
    display: none;
  }
  .button {
    padding: 2px; //also change .card padding
  }
}

.slider {
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  .cardContainer{
    overflow: hidden;
    .scrollable {      
      display: flex;
      overflow-y: hidden;
      overflow-x: auto;
      flex-grow: 1;
      z-index: 1;
      position: relative;
      height: 100%;
      margin-bottom: -20px;
      padding-bottom: 20px;
    }
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    bottom: 0;
    margin: -$button-size/2;
  
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    .button {
      background-color: #36454F;
      //border: solid 1px #36454F;
      &:hover, &:active, &:focus {
        background-color: #36454F;
      }
    }  
  }
}

.card {
  padding: 1px;
}